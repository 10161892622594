import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('.js-scroll-trigger').click(function() {
      $('.navbar-collapse').collapse('hide');
    });
  }
   
         
    

}
